/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var Sage = {
    // All pages
    'common': {
      init: function() {


        var scroll = new SmoothScroll('a[href*="#"]', {
          speed: 500,
          speedAsDuration: true,
          header: '.fixed-top'
        });

        var parallaxOn = false;
        $(window).resize( function(){
          if(!parallaxOn && $(window).width() > 991){
            $('.px-window').parallax();
            parallaxOn = true;
          }else if(parallaxOn  && $(window).width() < 992){
            $('.px-window').parallax('destroy');
            parallaxOn = false;
          }
        }).resize();


        var sliders = document.querySelectorAll('.slider');
        if (sliders) {
          for (var i = 0; i < sliders.length; i++) {
            var config = JSON.parse(sliders[i].getAttribute('data-slider'));
            if(config){
              var glide = new Glide(sliders[i], {
                type: 'carousel',
                autoplay: 5000,
                hoverpause: true,
                keyboard: true,
                gap: 0,
                perView: config.perView,
                breakpoints: {
                  992: {
                    perView: config.perView === 1 ? 1 : 4
                  },
                  768: {
                    perView: config.perView === 1 ? 1 : 3
                  },
                  576: {
                    perView: config.perView === 1 ? 1 : 2
                  },
                  400: {
                    perView:  config.perView === 1 ? 1 : 1
                  }
                }
              });
              glide.mount();
            }
          }
        }

      },
      finalize: function() {
        mediumZoom(document.querySelectorAll('[data-action="zoom"]'), {
          background: 'rgba(33, 37, 41, 0.98)',
          scrollOffset: 0,
        });

        // var heightFirstElement = $( ".pb-row" ).first().height();
        // console.log(heightFirstElement);

        var header = $(".navbar");
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();
          if (scroll >= 110) {
            header.addClass("fill");
          } else {
            header.removeClass("fill");
          }
        });

      }
    },
  };

  function calculateVH() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  

  var isTouchDevice = function () {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };
  var isSmallScreen = function () {
    return window.innerWidth < 992;
  };
  
  document.isTouch = isTouchDevice();
  
  var initBootstrapMenus = function () {
  
    // reset all menu handlers first, using jQuery off
    $(
      document, "nav.navbar li.dropdown > a.dropdown-toggle, nav.navbar li.dropdown-submenu > a.dropdown-toggle, nav.navbar li a:not(.dropdown-toggle)"
    ).off("click");
    $("nav.navbar li.dropdown, nav.navbar li.dropdown-submenu").off(
      "mouseenter mouseleave"
    );
  
    $(document).on("click", function (e) {
      if (
        !$(e.target).parents("nav.navbar:first").length > 0
      ) {
        $('nav.navbar li.dropdown .show').removeClass('show');
      }
    });
  
    const template = document.querySelector('#submenu-back-contents-template');
  
    $(".nav-sub:not(:has(> .nav-item .nav-link-back))").each(function () {
      const clone = template.content.querySelector('li:first-child').cloneNode(true);
      let $menu = $(this);
      $menu.prepend(clone);
      $menu.find('> .nav-item .nav-link-back').on('click', function () {
        $menu.removeClass('show');
      });
    });
  
  
    let menuTimeouts = [];
    // initialize all menus with unique id for quick state checking
    $(
      "nav.navbar li.dropdown:not([data-menu-id]), nav.navbar li.dropdown-submenu:not([data-menu-id])"
    ).each(function (e) {
      $(this).attr(
        "data-menu-id",
        "menu" + Math.round(Math.random() * 1000000000)
      );
    });
  
    // check if clicks or hovers must be used, depending on touch device or visible .navbar-toggle button
  
  
  
  
    // on window resize, remove all show classes from the submenus
    $(window).resize(function () {
      $('nav.navbar li.dropdown .show').removeClass('show');
    });
  
    $('.navbar-toggler').click(function () {
      // toggle body class
      if ($('body').hasClass('show-menu')) {
        $('nav.navbar li.dropdown .show').removeClass('show');
      }
      $('body').toggleClass('show-menu');
      $('html').toggleClass('show-menu');
    });
  
    $("nav.navbar li.dropdown").hover(
  
      function (e) {
        // show submenu
        clearTimeout(menuTimeouts[$(this).attr("data-menu-id")]);
        if (!isSmallScreen() && !isTouchDevice()) {
  
          var $subMenu = $(this).find(".nav-sub:first");
          $subMenu.addClass("show");
  
          if (!$subMenu.is(':entireonscreen')) {
            $subMenu.addClass("nav-sub-left");
          } else if (!$subMenu.hasClass("nav-sub-left")) {
            $subMenu.removeClass("nav-sub-left");
          }
        }
      },
      function (e) {
        if (!isSmallScreen() && !isTouchDevice()) {
          // hide submenu timed
          var $subMenu = $(this).find("> .nav-sub");
          menuTimeouts[$(this).attr("data-menu-id")] = setTimeout(() => {
            $subMenu.removeClass("show");
          }, 150);
        }
      }
    );
  
    // do not use hover, use clicks (smalls screens and touch devices)
    $(
      isSmallScreen() ? "nav.navbar li.dropdown > .toggle-sub, nav.navbar li.dropdown-submenu > .toggle-sub" : "nav.navbar li.dropdown > a.dropdown-toggle, nav.navbar li.dropdown-submenu > a.dropdown-toggle"
    ).on("click", function (e) {
      if (isSmallScreen() || isTouchDevice()) {
        if (isTouchDevice() && $(this).parents("li:first").find("> .nav-sub.show:first").length > 0) {
          return true;
        }
        e.preventDefault();
  
        var that = this;
        // hide all other submenus first (but not this one)
        $(this)
          .parents("ul:first")
          .find("> li.dropdown, > li.dropdown-submenu")
          .each(function (i) {
            if (
              $(this).attr("data-menu-id") !==
              $(that).parent().attr("data-menu-id")
            ) {
              $(this).find(".nav-sub").removeClass("show");
            }
          });
  
        var $subMenu = $(this).parents('li:first').find("> .nav-sub");
        if ($subMenu.length > 0) {
          if ($subMenu.hasClass("show")) {
            // if submenu of this menu item is showing, hide all submenus and go with the flow (e.g. open link)
            $(this)
              .parents("ul.navbar-nav:first")
              .find(".nav-sub")
              .removeClass("show");
            return true;
          } else {
            // if submenu of this menu item is hidden, show it and disable link functionality
            $subMenu.addClass("show");
            return false;
          }
        }
      }
      return true;
    });
  
    // always hide submenus when clicking a link without submenu
    $("nav.navbar li a:not(.dropdown-toggle").on("click", function (e) {
      if (!isSmallScreen() && !isTouchDevice()) {
        $(this).parents("ul.navbar-nav").find(".nav-sub").removeClass("show");
      }
    });
  
  };

  $.fn.isOnScreen = function (partial) {

    //let's be sure we're checking only one element (in case function is called on set)
    var t = $(this).first();
  
    //we're using getBoundingClientRect to get position of element relative to viewport
    //so we dont need to care about scroll position
    var box = t[0].getBoundingClientRect();
  
    //let's save window size
    var win = {
      h: $(window).height(),
      w: $(window).width()
    };
  
    //now we check against edges of element
  
    //firstly we check one axis
    //for example we check if left edge of element is between left and right edge of scree (still might be above/below)
    var topEdgeInRange = box.top >= 0 && box.top <= win.h;
    var bottomEdgeInRange = box.bottom >= 0 && box.bottom <= win.h;
  
    var leftEdgeInRange = box.left >= 0 && box.left <= win.w;
    var rightEdgeInRange = box.right >= 0 && box.right <= win.w;
  
  
    //here we check if element is bigger then window and 'covers' the screen in given axis
    var coverScreenHorizontally = box.left <= 0 && box.right >= win.w;
    var coverScreenVertically = box.top <= 0 && box.bottom >= win.h;
  
    //now we check 2nd axis
    var topEdgeInScreen = topEdgeInRange && (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);
    var bottomEdgeInScreen = bottomEdgeInRange && (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);
  
    var leftEdgeInScreen = leftEdgeInRange && (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);
    var rightEdgeInScreen = rightEdgeInRange && (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);
  
    //now knowing presence of each edge on screen, we check if element is partially or entirely present on screen
    var isPartiallyOnScreen = topEdgeInScreen || bottomEdgeInScreen || leftEdgeInScreen || rightEdgeInScreen;
    var isEntirelyOnScreen = topEdgeInScreen && bottomEdgeInScreen && leftEdgeInScreen && rightEdgeInScreen;
  
    return partial ? isPartiallyOnScreen : isEntirelyOnScreen;
  
  };
  
  $.expr.filters.onscreen = function (elem) {
    return $(elem).isOnScreen(true);
  };
  
  $.expr.filters.entireonscreen = function (elem) {
    return $(elem).isOnScreen(false);
  };

  calculateVH();
  initBootstrapMenus();

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
